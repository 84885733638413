
.vue-notification-group{
  top: 35vh !important;
  display: grid !important;
  place-content: center;
  // left: calc(50% - 100px) !important;
}

.vue-notification-phase{
  background: transparent !important;
}

.vue-notification-phase .notify-text {
  background: #3333ff; /* Adjusted to a more readable dark blue */
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 20px; /* More padding for readability */
  font-size: 14px; /* Smaller font size */
  font-weight: 500;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  z-index: 9999;
  font-family: "Comfortaa", cursive;
}

.vue-notification-phase .notify-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust height for a cleaner look */
  background: linear-gradient(45deg, #00ffcc, #00ffcc, #ff00ff);
  border-radius: 8px 8px 0 0;
}
