form {
  padding-left: 2em;
  width: 90%;

  @media(max-width: 840px){
    width: auto;
    // margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  @media(max-width: 420px){
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  input, textarea {
    width: 100%;
    border: none;
    outline: none;
    font-size: 13px;
    letter-spacing: 1px;
    font-family: $font-comfortaa;

    &:disabled {
      background: $color-grey;
    }
  }
  input[type="checkbox"] {
    width: initial;
  }
  input[type="range"] {
    -webkit-appearance: none;
    background: $color-2;
    border: none;
    height: 4px;
    padding: 0;
    margin: 10px 0;
    cursor: pointer;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: $color-blue;
      width: 13px;
      height: 13px;
      border: none;
      border-radius: 100%;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      background: $color-blue;
      width: 13px;
      height: 13px;
      border: none;
      border-radius: 100%;
    }
    &::-moz-range-track {
      background: $color-2;
    }
  }
}