/*
.one-wide {
  lost-column: 1/6;
}
.two-wide {
  lost-column: 1/6;
}
.three-wide {
  lost-column: 1/6;
}
.four-wide {
  lost-column: 4/6;
}
.five-wide {
  lost-column: 5/6
}
*/